'format es6';
'use strict';

import $ from 'jquery';

let videoOverlay;
let player;
let close;
let shareURL;
let mailtoText;
let YTplayer;
let isPlayerReady;

const CLOSE_ANIMATION_TIME = 600;
const ACTIVE_CLASS = 'opened';

const MAILTOTEXT = 'mailto';
const TARGET_ELEMENT_DATA = 'video-btn';
const TARGET_ELEMENT_SELECTOR = `[data-${TARGET_ELEMENT_DATA}]`;

const FB_APP_ID = '';
const EVENT_NAMESPACE = 'lagrange';

function closeVideoPlayer(e) {
	// e.preventDefault();
	videoOverlay.removeClass(ACTIVE_CLASS);

	setTimeout(() => {
		YTplayer.destroy();
	}, CLOSE_ANIMATION_TIME);
}

function setupYoutube(e) {
	e.preventDefault();

	const clicked = $(e.currentTarget);
	const clickedID = clicked.data(TARGET_ELEMENT_DATA);

	// 4. The API will call this function when the video player is ready.
	function onPlayerReady(event) {
		event.target.playVideo();
		videoOverlay.addClass(ACTIVE_CLASS);
	}

	// 5. The API calls this function when the player's state changes.
	//    The function indicates that when playing a video (state=1),
	//    the player should play for six seconds and then stop.
	function onPlayerStateChange(event) {
		if (event.data === 0) {
			closeVideoPlayer();
		}
	}

	const source = `https://www.youtube.com/embed/${clicked.data(TARGET_ELEMENT_DATA)}?rel=0&modestbranding=1&showinfo=0&enablejsapi=1`;
	const html = `<iframe width="100%" height="100%" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen data-player></iframe>`;

	player.empty().append(html);

	if (isPlayerReady && !player.is(':empty')) {
		const youtubePlayElem = document.querySelector('[data-player]');
		YTplayer = new YT.Player(youtubePlayElem, {
			events: {
				onReady: onPlayerReady,
				onStateChange: onPlayerStateChange,
			},
		});
	}
}

window.onYouTubeIframeAPIReady = function() {
	isPlayerReady = true;
};


export default {
	init() {
		const playerBtns = $(TARGET_ELEMENT_SELECTOR);
		videoOverlay = $(`
			<div class="video-overlay">
				<div class="close"><span>&times;</span></div>
				<div class="share">
					<div class="facebook share-button" data-facebook data-video-share>
						<i class="facebook icon-facebook"></i>
					</div>
					<div class="twitter share-button" data-twitter data-video-share>
						<i class="twitter icon-twitter"></i>
					</div>
					<div class="linkedin share-button" data-linkedin data-video-share>
						<i class="linkedin icon-linkedin"></i>
					</div>
					<div class="mail share-button" data-mailto data-video-share>
						<i class="fa fa-envelope"></i>
					</div>
				</div>
				<div class="player"></div>
			</div>
		`);
		player = videoOverlay.find('.player');
		close = videoOverlay.find('.close');
		
		if (playerBtns.length === 0) return;

		$('body').append(videoOverlay);

		const facebookBtn = $('[data-facebook]', videoOverlay);
		const twitterBtn = $('[data-twitter]', videoOverlay);
		const linkedinBtn = $('[data-linkedin]', videoOverlay);
		const mailtoBtn = $('[data-mailto]', videoOverlay);

		facebookBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
			e.preventDefault();
		
			const url = `https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${shareURL}`;
			window.open(url, 'Partager', 'width=520,height=570');
		});

		twitterBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
			e.preventDefault();

			const url = `https://twitter.com/intent/tweet/?url=${shareURL}`;
			window.open(url, 'Partager', 'width=560,height=250');
		});

		linkedinBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
			e.preventDefault();

			const url = `https://www.linkedin.com/cws/share?url=${shareURL}`;
			window.open(url, 'Partager', 'width=520,height=570');
		});

		mailtoBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
			e.preventDefault();
			
			const shareMailText = mailtoText;
			const url = `${shareURL}`;

			window.location.href = `mailto:?Subject=${shareMailText}&body=${url}`;
		});

		close.on(`click.${EVENT_NAMESPACE}.video_player`, closeVideoPlayer);
		playerBtns.on(`click.${EVENT_NAMESPACE}.video_player`, setupYoutube);
	},
};
