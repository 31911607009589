'format es6';
'use strict';

import Promise from 'Promise';

import manifest from './manifest';

import gsap, { TweenMax } from 'gsap';
import { StepVideo } from './StepVideo';
import { StepInteractive } from './StepInteractive';
import { STEP_TYPE_VIDEO, STEP_TYPE_INTERACTIVE } from './Experience';

export class Demo {
	constructor(demo, ctn, experienceCtn) {
		this.isDebug = true;
		this.name = demo.name;
		this.steps = demo.steps;
		this.loadingLoopUrl = demo.loadingLoop;
		this.node = document.createElement('div');
		this.node.classList.add('experience-demo');
		ctn.appendChild(this.node);
		this.experienceCtn = experienceCtn;
		this.currentStepIndex = 0;

		this.isLoading = true;

		this.sequences = [];

		this.generateLoading();

		console.log('Launching demo:', this.name);

		this.preloadSequences();
	}

	progress = () => {
		if (this.steps.length > this.currentStepIndex) {
			if (this.currentStep) this.currentStep.remove();

			this.currentStep = this.steps[this.currentStepIndex];

			this.currentStep.appendTo(this.node);
			this.currentStep.start();

			if (this.currentStep.onProgress) {
				this.currentStep.checkProgress((progress = 0) => {
					this.updateNavSteps(this.currentStep, progress);
				});
			}

			this.currentStep.ended.then(() => {
				this.currentStep.remove();
				this.currentStepIndex++;
				console.log('ended > next step');
				this.progress();
			}).catch((e) => {
				console.error(e);
			});
		} else {
			console.log('test');
			this.onEnded();
		}
	}

	generateLoading = () => {
		const loading = document.createElement('div');
		loading.classList.add('demo-loading');
		loading.innerHTML = `
			<div class="loading-text">
				Loading
				<div class="bar">
					<div class="inner-bar"></div>
				</div>
			</div>
			<video src="${this.loadingLoopUrl}" preload autoplay loop webkit-playsinline playsinline></video>
			`;

		this.randomQuote = document.createElement('div');
		this.randomQuote.classList.add('quote');
		loading.appendChild(this.randomQuote);

		this.randomQuotes(this.randomQuote);

		this.loading = loading;
	}

	changeQuote = (elem) => {
		elem.innerHTML = manifest.loadingQuotes[Math.floor(Math.random() * manifest.loadingQuotes.length)];
		TweenMax.fromTo(elem, 0.3, { opacity: 0, y: '100%' }, { opacity: 1, y: '0%', delay: 0.6 });

		TweenMax.delayedCall(4, this.randomQuotes, [elem]);
	}

	randomQuotes = (elem) => {
		if (this.isLoading) {
			if (elem.innerHTML === '') {
				this.changeQuote(elem);
			} else {
				TweenMax.to(elem, 0.3, { opacity: 0, y: '100%', onComplete: this.changeQuote, onCompleteParams: [elem] });
			}
		}
	}

	showLoading = () => {
		this.node.appendChild(this.loading);
		TweenMax.fromTo(this.loading, 0.3, { opacity:0 }, { opacity: 1, ease: gsap.Expo.easeOut });
	}
	
	hideLoading = (cb) => {
		this.isLoading = false;
		TweenMax.to(this.loading, 0.3, {
			opacity: 1,
			ease: gsap.Expo.easeOut,
			onComplete: () => {
				this.node.removeChild(this.loading);
				cb();
			},
		});
	}

	beginDemo = () => {
		console.log('Beginning demo:', this.name);
		this.hideLoading(() => {
			this.progress();
			this.generateNavigation();
		})
	}

	preloadSequences = () => {
		console.log('Preloading sequences for:', this.name);

		const sequencesLoaded = [];

		this.showLoading();
		
		this.steps = this.steps.map((step) => {
			let expStep;
			
			switch (step.type) {
				case STEP_TYPE_INTERACTIVE:
					expStep = new StepInteractive(step);
					sequencesLoaded.push(expStep.ready);
					break;
				
				default:
				case STEP_TYPE_VIDEO:
					expStep = new StepVideo(step);
					break;
			}

			return expStep;
		});

		Promise.all(sequencesLoaded).then(this.beginDemo);
	}

	getStepIndexBySlug = (slug) => {
		return this.steps.findIndex((step) => {
			return step.slug === slug;
		});
	}

	onEnded = () => {
		const endFrame = document.createElement('div');
		endFrame.classList.add('end-frame');

		this.nav.parentNode.removeChild(this.nav);

        const otherLabel = this.slug === 'pour-over' ? 'cold brew' : 'pour over';
        const otherSlug = this.slug === 'pour-over' ? 'cold-brew' : 'pour-over';

        endFrame.innerHTML = `
			<div class="title">THE ARTISAN COFFEE SHOP</div>
			<div>
				<a href="/products">Discover our products</a>
				<a href="/experience?experience=${otherSlug}">Try the ${otherLabel}</a>
			</div>
		`;

		TweenMax.fromTo(endFrame, 0.3, { opacity: 0 }, { opacity: 1 });

		this.node.appendChild(endFrame);
	}

	/*********
	 *  NAVIGATION
	 */

	generateNavigation = () => {
		this.navSteps = this.steps.filter(step => step.slug && step.name);
		this.nav = this.navSteps.reduce((nav, step) => {
			const stepNode = document.createElement('div');
			stepNode.classList.add('experience-nav-step');
			stepNode.setAttribute('data-step', step.slug);
			stepNode.setAttribute('data-step-progress', step.slugProgress);
			stepNode.innerHTML = `
				<div class="circle"></div>
				<div class="line"><div class="inner-line"></div></div>
				<div class="label">${step.name}</div>`;
			nav.appendChild(stepNode);
			stepNode.__innerLine = stepNode.querySelector('.inner-line');
			stepNode.__circle = stepNode.querySelector('.circle');

			stepNode.__circle.addEventListener('click', () => {
				this.onClickNavNode(stepNode);
			});

			return nav;
		}, document.createElement('div'));
		this.nav.classList.add('experience-nav');

		this.experienceCtn.insertAdjacentElement('beforebegin', this.nav);
	}

	onClickNavNode = (step) => {
		this.currentStepIndex = this.getStepIndexBySlug(step.getAttribute('data-step'));
		this.progress();
		this.updateNavSteps(this.currentStep);
	}

	updateNavSteps = (currentStep, progress = 0) => {
		let wasActive = false;
		Array.from(this.nav.children).reverse().forEach((step) => {
			// if (currentStep.type === 'video') {
				if (step.getAttribute('data-step-progress') === currentStep.slugProgress) {
					step.classList.add('active');
					wasActive = true;
					TweenMax.to(step.__innerLine, 0.3, { width:`${progress * 100}%` });
				} else {
					step.classList.remove('active');
					step.__innerLine.style.width = 0;
				}
	
				if (wasActive) {
					step.classList.add('on');
				} else {
					step.classList.remove('on');
				}
			// }
		});
	}
}
