'format es6';
'use strict';

import $ from 'jquery';

function displayModal(e) {
	const modalBtn = $('[data-shop-btn]');
	const modal = $('[data-shop-overlay]');
	const contentToClone = $(e.currentTarget).parent().find('[data-shop-content]');
	contentToClone.clone().appendTo(modal);
	
	const closebtn = $('[data-close]', modal);
	modalBtn.off('click.brim.modal');
	
	function close() {
		modal.removeClass('opened');
		
		setTimeout(() => {
			modal.empty();
			modalBtn.on('click.brim.modal', displayModal);
		}, 600);
	}
	
	setTimeout(() => {
		modal.addClass('opened');
	}, 300);
	
	const modal_content = modal.find('[data-shop-content]');
	modal_content.off('click.ent.popin').on('click.ent.popin', (evt) => {
		evt.stopPropagation();
	});

	closebtn.off('click.mp.popin').on('click.mp.popin', close);
	modal.off('click.mp.popin').on('click.mp.popin', close);
}

function setupClicks() {
	$('[data-shop-btn]').off('click.brim.modal').on('click.brim.modal', displayModal);
}

export default {
	init() {
		setupClicks();
	},
};
