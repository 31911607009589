'format es6';
'use strict';

import $ from 'jquery';

const SNIPPET_SELECTOR = '[data-share-snippet]';
const SNIPPET_DATA_SELECTOR = '[data-share]';
const SNIPPET_ID_DATA = 'snippet-id';

const FB_APP_ID = '152347095489349';
const EVENT_NAMESPACE = 'lagrange';

function createSnippet(snippet) {
	const _snippet = $(snippet);

	const currURL = window.location.href;
	const facebookBtn = $('[data-share-facebook]', _snippet);
	const twitterBtn = $('[data-share-twitter]', _snippet);

	facebookBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
		e.preventDefault();
		const url = `https://www.facebook.com/dialog/share?app_id=${FB_APP_ID}&display=popup&href=${currURL}`;
		window.open(url, 'Partager', 'width=520,height=570');
	});

	twitterBtn.on(`click.${EVENT_NAMESPACE}.video_player`, (e) => {
		e.preventDefault();
		
		const twitterText = $('[data-twitter-share-text]').data('twitter-share-text');
		console.log(twitterText);
		const url = `https://twitter.com/intent/tweet/?url=${currURL}&text=${encodeURIComponent(twitterText)}`;
		window.open(url, 'Partager', 'width=560,height=250');
	});
}

export default {
	init() {
		$(SNIPPET_SELECTOR).each((i, snippet) => {
			createSnippet(snippet);
		});
	},
};
