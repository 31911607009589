'use strict';
'format es6';

export const isElementVisible = (el) => {
	const rect = el.getBoundingClientRect();

	return getComputedStyle(el, null).display !== 'none' && (
		rect.top >= -el.clientHeight &&
		rect.left >= 0 &&
		rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + el.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};