'format es6';
'use strict';

import {
	ELEM_BOTTOM,
	ELEM_TOP,
	ELEM_CENTER,
	SCREEN_BOTTOM,
	SCREEN_TOP,
	SCREEN_CENTER,
} from '../lagrange/animation/Animator';

export const Animations = {
	get(isMobile) {
		const animations = {};
		
		animations.reveal_img_mask = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					scale: 0.7,
					y: isMobile ? '-2vh' : '-10%',
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					scale: 1,
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: isMobile ? '2vh' : '10%',
				},
			],
			children: [
				{
					ease: 'easeInOutCubic',
					selector: 'img, video',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							scale: 1 / 0.7,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							scale: 1,
						},
					],
				},
			],
		};

		animations.reveal_bg = {
			ease: 'easeInOutCubic',
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					scaleX: isMobile ? 1 : 0,
				},
				{
					when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
					scaleX: isMobile ? 1 : 1,
				},
			],
		};

		animations.reveal_recipe = {
			props: [
				{
					when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
					y: isMobile ? '15%' : '10vh',
				},
				{
					when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
					y: isMobile ? '-15%' : '-10vh',
				},
			],
			children: [
				{
					selector: '.note',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							x: 20,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							x: 0,
						},
					],
				},
				{
					selector: 'h1',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							x: 40,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							x: 0,
						},
					],
				},
				{
					selector: 'a:not(.hs-link)',
					props: [
						{
							when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
							x: 60,
						},
						{
							when: `${ELEM_CENTER}_${SCREEN_CENTER}`,
							x: 0,
						},
					],
				},
			],
		};

		animations.semi_fixed = [
			{
				when: `${ELEM_TOP}_${SCREEN_TOP}`,
				y: 0,
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '80%',
			},
		];

		animations.crux_bg = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: '-50%',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '50%',
			},
		];

		animations.parallax_mini = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: '4vh',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '-4vh',
			},
		];

		animations.parallax_mini_inv = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: '-4vh',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '4vh',
			},
		];

		animations.parallax_little = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: isMobile ? '2%' : '10vh',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: isMobile ? '-2%' : '-10vh',
			},
		];

		animations.parallax_little_inv = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: '-10vh',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: '10vh',
			},
		];

		animations.parallax_medium = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: isMobile ? '10vh' : '25vh',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: isMobile ? '-10vh' : '-25vh',
			},
		];

		animations.parallax_graphic_element = [
			{
				when: `${ELEM_TOP}_${SCREEN_BOTTOM}`,
				y: isMobile ? '10%' : '10%',
			},
			{
				when: `${ELEM_BOTTOM}_${SCREEN_TOP}`,
				y: isMobile ? '-10%' : '-10%',
			},
		];

		return animations;
	},
};
