'format es6';
'use strict';

import $ from 'jquery';

import { isMobile } from './utils/isMobile';

let currentHotspot = null;
let overlayContent;
let productOverlay;
let hotspots;
let hotspotContainer;
let lookbook;

const ACTIVE_CLASS = 'active';
const OVERFLOW_CLASS = 'overflow';

function positionOverlay(hotspot) {
	const hpOffset = hotspot.position();

	productOverlay.height('');
	productOverlay.removeClass(OVERFLOW_CLASS);
	
	if (!isMobile(767)) {
		productOverlay.css({
			top: `calc(${Math.round(hpOffset.top / lookbook.height() * 100)}% + ${hotspot.height() + 20}px)`,
			left: `calc(${Math.round(hpOffset.left / lookbook.width() * 100)}% + ${hotspot.width() / 2}px)`,
		});
		
		const overlayTop = productOverlay.position().top + productOverlay.height() + 20;
		if (overlayTop > lookbook.height()) {
			productOverlay.height(lookbook.height() - overlayTop + productOverlay.height() - 30);
			productOverlay.addClass(OVERFLOW_CLASS);
		} 
	} else {
		productOverlay.css({
			top: '',
			left: '',
		});
	}
}

function handleHotspotClick(e) {
	e.preventDefault();
	e.stopPropagation();

	const hp = $(e.currentTarget);
	
	if (hp.hasClass(ACTIVE_CLASS)) {
		hp.removeClass(ACTIVE_CLASS);
		hotspotContainer.removeClass(ACTIVE_CLASS);
		productOverlay.removeClass(ACTIVE_CLASS);
		return;
	}

	hotspots.removeClass('active');

	const wasActive = productOverlay.hasClass(ACTIVE_CLASS);
	wasActive && productOverlay.removeClass(ACTIVE_CLASS);

	setTimeout(() => {
		hp.addClass(ACTIVE_CLASS);

		overlayContent
			.empty()
			.append(hp.find('[data-content]').clone());

		positionOverlay(hp);
		
		productOverlay.addClass(ACTIVE_CLASS);
		currentHotspot = hp.data('hotspot-id');
		
		if (isMobile(767)) {
			hotspotContainer.addClass(ACTIVE_CLASS);
			hotspotContainer.off('click.lookbook').on('click.lookbook', (_e) => {
				_e.preventDefault();
				_e.stopPropagation();
				hp.trigger('click');
			});
		} 
	}, wasActive ? 600 : 0);
}

export default {
	init() {
		lookbook = $('[data-lookbook]');
		hotspotContainer = $('.hotspot-container', lookbook);
		hotspots = $('[data-hotspot]', lookbook);
		productOverlay = $('[data-product-overlay]', lookbook);
		overlayContent = $('[data-content]', productOverlay);

		hotspots.on('click', handleHotspotClick);
	},
};
