'format es6';
'use strict';

import $ from 'jquery';
try{require('source-map-'+'support').install();}catch(e){}

const name = 'brim';

const ns = window[name] = (window[name] || {});

ns.window = $(window);

// window.requestAnimFrame = (() => {
// 	return window.requestAnimationFrame ||
// 		window.webkitRequestAnimationFrame ||
// 		window.mozRequestAnimationFrame ||
// 		(callback) => {
// 			window.setTimeout(callback, 1000 / 60);
// 		};
// })();

ns.gapiLoad = {
	callbacks: [],
	register(cb) {
		this.callbacks.push(cb);
	},
	loaded() {
		this.callbacks.forEach(cb => cb());
	}
};


export default ns;

