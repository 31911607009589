'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'Promise';
import ImageSequence from '../sequence/ImageSequence';
import Snap from 'snapsvg';
import gsap, { TweenMax, BezierPlugin } from 'gsap';

import EventDispatcher from '../utils/EventDispatcher';
import { closestPointPath } from '../utils/closestPointPath';
import { getLengthAtPoint } from '../utils/getLengthAtPoint';
import numMap from '../utils/numMap';
import { STEP_TYPE_INTERACTIVE } from './Experience';
import '../utils/SvgClassListShim';

const xmlns = 'http://www.w3.org/2000/svg';

const body = document.querySelector('body');

const AUTO_COMPLETE_PRC = 0.95;

const noop = () => {};

const CURSOR_CENTER = '58px 23px';

export class StepInteractive extends EventDispatcher {
	constructor(stepInfo) {
		super();

		this.type = STEP_TYPE_INTERACTIVE;
		this.stepInfo = stepInfo;
		this.name = stepInfo.name;
		this.slug = stepInfo.slug;
		this.slugProgress = stepInfo.slugProgress;

		this.resize();

		this.canvas = document.createElement('canvas');
		this.node = document.createElement('div');
		this.node.classList.add('experience-interactive');
		this.node.appendChild(this.canvas);
		this.sequence = new ImageSequence(this.canvas, stepInfo.options);

		this.node.classList.add(stepInfo.slug);

		this.hasOverlay = this.generateOverlay(stepInfo);

		this.ui = {};

		this.hasSound = !!stepInfo.soundLoop;
		if (this.hasSound) {
			this.audio = document.createElement('audio');
			this.node.appendChild(this.audio);
			this.audio.src = stepInfo.soundLoop;
			this.audio.preload = true;
			this.audio.loop = true;
			this.audio.volume = 0.0;
		}

		this._sequenceResolve = null;
		this.uiReady = new Promise((resolve) => {
			this.generateUI(stepInfo.svg, resolve);
		});
	}

	generateOverlay = (infos) => {
		if (!infos.overlay) return false;

		this.overlay = document.createElement('div');
		this.overlay.classList.add('interactive-overlay');

		const overlayElems = ['title', 'desc', 'button'];

		overlayElems.forEach((elem, i) => {
			const ctn = document.createElement('div');
			const el = document.createElement('div');
			el.classList.add(`interactive-${elem}`);
			el.innerHTML = infos.overlay[elem];
			ctn.appendChild(el);
			this.overlay.appendChild(ctn);
			this[elem] = el;
		});

		return true;
	}

	onClickButton = () => {
		this.playAnim();
		
		TweenMax.to(this.overlay, 0.3, { 
			opacity: 0,
			onComplete: () => {
				this.node.removeChild(this.overlay);
			}
		});
	}

	generateUI = (svgUrl, resolve) => {
		this.svg = document.createElementNS(xmlns, 'svg');
		this.node.appendChild(this.svg);

		Snap.load(svgUrl, (d) => {
			const snap = Snap(this.svg);
			const g = snap.group();
			g.append(d);

			this.ui.cursor = this.svg.querySelector('#cursor');
			this.ui.start = this.svg.querySelector('#start');
			this.ui.end = this.svg.querySelector('#end');
			this.ui.path = this.svg.querySelector('#path');
			this.ui.path_parts = this.svg.querySelector('#path_anim').children || this.svg.querySelector('#path_anim').childNodes;
			this.ui.check = this.svg.querySelector('#check');
			this.ui.label = this.svg.querySelector('#label');

			this.setInitialUIStatus();

			resolve();
		});
	}

	setInitialUIStatus = () => {
		TweenMax.set(this.ui.start, { opacity:0 });
		TweenMax.set(this.ui.end, { opacity:0 });
		TweenMax.set(this.ui.path, { opacity:0 });
		TweenMax.set(this.ui.check, { opacity:0 });
		TweenMax.set(this.ui.label, { opacity:0 });

		const firstPoint = this.ui.path.getPointAtLength(0);
		// TweenMax.set(this.ui.cursor, { opacity: 0, scale: this.UI_SCALE, transformOrigin: CURSOR_CENTER });
		this.ui.cursor.setAttribute('transform', '');
		this.ui.cursor.setAttribute('data-svg-origin', '');
		TweenMax.set(this.ui.cursor, { opacity: 0, x: firstPoint.x, y: firstPoint.y, transformOrigin: CURSOR_CENTER });

		TweenMax.set('#path_anim > *', { opacity:0 });
	}

	start = () => {
		this.canvas.width = this.node.clientWidth;
		this.canvas.height = this.node.clientHeight;
		this.sequence.gotoInstant(0);
		this.sequence.updateCanvas();
		window.addEventListener('resize', this.resize);

		this.svg.classList.remove('complete');
		this.node.classList.add('active');

		if(this.overlay) {
			this.node.appendChild(this.overlay);
		}

		if (this.hasSound) {
			TweenMax.to(this.audio, 0.6, { volume: 1 });
			this.audio.play();
		}

		this.setInitialUIStatus();

		if (!this.hasOverlay) {
			this.playAnim();
		} else {
			TweenMax.set(this.overlay, { opacity: 1 });
			TweenMax.fromTo(this.title, 0.6, { y: '100%' }, { y: '0%', ease: gsap.Expo.easeOut, delay: 0.3 });
			TweenMax.fromTo(this.desc, 0.6, { y: '100%' }, { y: '0%', ease: gsap.Expo.easeOut, delay: 0.3 });
			TweenMax.fromTo(this.button, 0.6, { y: '100%' }, { y: '0%', ease: gsap.Expo.easeOut, delay: 0.4 });
		}
	}

	playAnim = () => {
		console.log('Play anim');
		this.sequence.playIntro(() => {
			this.animateInUI().then(this.startInteraction);
		});
	}

	animateInUI = () => {
		return new Promise((resolve) => {
			TweenMax.set(this.ui.check, { opacity:0 });
			TweenMax.set(this.ui.label, { opacity:0 });

			TweenMax.fromTo(this.ui.start, 0.4, { scale: 0, transformOrigin: "50% 50%" }, { scale: this.UI_SCALE, ease: gsap.Back.easeOut });
			TweenMax.fromTo(this.ui.start, 0.3, { opacity: 0 }, { opacity:1, ease: gsap.Expo.easeOut });

			let delay = 0.4;

			TweenMax.staggerTo(
				this.ui.path_parts,
				0.3,
				{ opacity: 1, ease: gsap.Expo.easeOut, delay },
				this.stepInfo.lineAnimDuration / this.ui.path_parts.length,
			);

			delay += this.stepInfo.lineAnimDuration;

			TweenMax.fromTo(this.ui.end, 0.4, { scale: 0, transformOrigin: "50% 50%" }, { scale: this.UI_SCALE, ease: gsap.Back.easeOut, delay });
			TweenMax.fromTo(this.ui.end, 0.3, { opacity: 0 }, { opacity:1, ease: gsap.Expo.easeOut, delay });

			delay += 0.2;

			TweenMax.fromTo(this.ui.cursor, 0.4, { scale: 0 }, { scale: this.UI_SCALE, ease: gsap.Back.easeOut, delay });
			TweenMax.fromTo(this.ui.cursor, 0.3, { opacity:0 }, { opacity:1, ease: gsap.Expo.easeOut, delay, onComplete: resolve });
		});
	}

	animateOutUI = () => {
		TweenMax.to(this.ui.start, 0.4, { scale: 0, transformOrigin: "50% 50%", ease: gsap.Back.easeIn });
		TweenMax.to(this.ui.start, 0.3, { opacity:0, ease: gsap.Expo.easeIn });

		TweenMax.fromTo(this.ui.check, 0.6, { scale: 0, transformOrigin: '25% 75%', opacity:0 }, { opacity:1, scale:1, ease: gsap.Back.easeOut });
		TweenMax.fromTo(this.ui.label, 0.6, { scale: 0, transformOrigin: '50% 50%', opacity:0 }, { opacity:1, scale:1, ease: gsap.Back.easeOut });

		let delay = 0.4;

		TweenMax.staggerTo(
			this.ui.path_parts,
			0.3,
			{ opacity: 0, ease: gsap.Expo.easeOut, delay },
			this.stepInfo.lineAnimDuration / this.ui.path_parts.length,
		);

		delay += this.stepInfo.lineAnimDuration;

		if (this.hasSound) {
			TweenMax.to(this.audio, 0.4, { volume: 0, delay });
		}

		TweenMax.to(this.ui.cursor, 0.4, {
			opacity:0,
			scale: 0,
			transformOrigin: CURSOR_CENTER,
			ease: gsap.Back.easeIn,
			delay,
			onComplete: () => {
				this._sequenceResolve();
			},
		});
	}

	startInteraction = () => {
		this.ui.cursor.addEventListener('mousedown', this.startDrag);
		this.ui.cursor.addEventListener('touchstart', this.startDrag);
	}

	startDrag = (e) => {
		this.svg.addEventListener('mousemove', this.dragMove);
		body.addEventListener('mouseup', this.stopDrag);

		this.svg.addEventListener('touchmove', this.dragMove);
		body.addEventListener('touchend', this.stopDrag);

		this.svgpos = this.svg.getBoundingClientRect();

		TweenMax.to(this.ui.start, 0.3, { scale: this.UI_SCALE * 0.75 });
		TweenMax.to(this.ui.end, 0.3, { scale: this.UI_SCALE + 1.25, delay: 0.1 });

		this.svg.classList.add('dragging');
		
		if (e.type === 'touchstart') {
			e.preventDefault();
		}
	}

	updateSequence(closest, isTweening = false) {
		let data = this.stepInfo.options;
		const totalLength = this.ui.path.getTotalLength();
		let prc = getLengthAtPoint(this.ui.path, closest) / totalLength;
		this.cursorPercent = prc;

		if (prc >= this.stepInfo.percentAutoComplete && !isTweening) {
			this.stopDrag();
		} else {
			prc = numMap(prc, 0, 1, data.nIntroFrames, data.lastFrame) / data.lastFrame;
			this.sequence.gotoInstant(prc);
		}
	}

	dragMove = (e) => {
		const scale = this.svg.getBoundingClientRect().width / 1920;

		let offset;
		if (e.type === 'mousemove') {
			offset = {
				x: e.offsetX,
				y: e.offsetY,
			};
		} else {
			offset = { 
				x: e.touches[0].clientX - this.svgpos.left,
				y: e.touches[0].clientY - this.svgpos.top, 
			};
		}
		
		const closest = closestPointPath(this.ui.path, [ offset.x / scale, offset.y / scale ]);
		TweenMax.set(this.ui.cursor, { x: closest.x, y: closest.y });

		//console.log(e, offset.x, offset.y, '--', closest.x, closest.y);

		this.updateSequence(closest);
		
		if (e.type === 'touchmove') {
			e.preventDefault();
		}
	}

	getPath(start, end) {
		const precision = 4;
		const points = [];

		for (var px = start; px <= end; px += precision) {
			const pt = this.ui.path.getPointAtLength(px);
			points.push({ x: pt.x, y: pt.y });
		}

		return points;
	}

	getReversePath() {
		const end = this.cursorPercent * this.ui.path.getTotalLength();
		return this.getPath(0, end).reverse();
	}

	bezierTo(elem, values, cb = noop) {
		TweenMax.to(elem, 0.6, {
			bezier: { values, ease: gsap.Expo.easeInOut },
			onUpdate: () => {
				const ts = elem._gsTransform;
				this.updateSequence({ x: ts.x, y: ts.y }, true);
			},
			onComplete: cb,
		});
	}

	stopDrag = () => {
		this.svg.classList.remove('dragging');

		if (this.cursorPercent < this.stepInfo.percentAutoComplete) {
			const path = this.getReversePath();
			this.bezierTo(this.ui.cursor, path);

			TweenMax.to(this.ui.end, 0.3, { scale: this.UI_SCALE });
			TweenMax.to(this.ui.start, 0.3, { scale: this.UI_SCALE, delay: 0.1 });
		} else {
			this.svg.classList.add('complete');
			const length = this.ui.path.getTotalLength();
			const path = this.getPath(this.cursorPercent * length, length);
			this.bezierTo(this.ui.cursor, path, this.animateOutUI);
			
			TweenMax.to(this.ui.end, 0.3, { opacity: 0, scale: this.UI_SCALE, ease: gsap.Expo.easeIn });

			this.ui.cursor.removeEventListener('mousedown', this.startDrag);
		}

		this.svg.removeEventListener('mousemove', this.dragMove);
		body.removeEventListener('mouseup', this.stopDrag);

		this.svg.removeEventListener('touchmove', this.dragMove);
		body.removeEventListener('touchend', this.stopDrag);
	}

	setListeners = () => {
		this.ready = this.ready || Promise.all([this.sequence.init(), this.uiReady]);
		this.ended = new Promise(this.onEnded);

		if (this.button) {
			this.button.removeEventListener('click', this.onClickButton);
			this.button.addEventListener('click', this.onClickButton);
		}
	}

	appendTo = (elem) => {
		elem.appendChild(this.node);
		this.setListeners();
	}

	remove = () => {
		window.removeEventListener('resize', this.resize);
		
		if (this.node.parentNode){
			this.node.parentNode.removeChild(this.node);
		}
	}

	onEnded = (resolve) => {
		this._sequenceResolve = resolve;
	}

	resize = () => {
		this.UI_SCALE = window.innerWidth <= 768 ? 2 : 1;

		if (this.ui && this.ui.end) {
			TweenMax.to(this.ui.end, 0.3, { scale: this.UI_SCALE });
			TweenMax.to(this.ui.start, 0.3, { scale: this.UI_SCALE });
			TweenMax.to(this.ui.cursor, 0.3, { scale: this.UI_SCALE, transformOrigin: CURSOR_CENTER });
		}
	}
}
