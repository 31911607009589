'format es6';
'use strict';

import $ from 'jquery';
import './ArrayFromPolyfill';

import slick from 'slick-carousel';
import imagesLoaded from 'imagesloaded';
import { docReady } from './utils/docReady';
import { isEdge } from './utils/browserDetect';
import { isMobile as checkMobile } from './utils/isMobile';

import Scrollbar from 'smooth-scrollbar';
import { Animator } from '../lagrange/animation/Animator';
import { Animations } from './Animations';
import { ResponsiveVideos } from './ResponsiveVideos';

import { Experience } from './experience/Experience';

import { isNativeScrolling } from './AppSettings';
let sb;

import ExperiencePlayer from './ExperiencePlayer';
import VideoPlayer from './VideoPlayer';
import ProductFeatures from './ProductFeatures';
import StickySidebar from './StickySidebar';
import Menu from './Menu';
import InPageNav from './InPageNav';
import Reveal from './Reveal';

import ShopModal from './ShopModal';
import HeaderVideo from './HeaderVideo';
import ShareSnippet from './ShareSnippet';


docReady.then(() => {
	if (isEdge([12, 13, 14, 15])) {
		$('img').removeAttr('srcset').removeAttr('sizes');
	}
	
	const responsiveVideo = new ResponsiveVideos();
	responsiveVideo.init();
	
	const main = document.querySelector('[data-main-scrollbar]');
	const navBlog = document.querySelector('[data-blog-scrollbar]');	
	
	if (main) {
		if (!isNativeScrolling) {
			main.setAttribute('data-has-scrollbar', true);
			sb = Scrollbar.init(main);
			
			if (navBlog) {
				Scrollbar.init(navBlog);
			}
		} else if (checkMobile(1024) && !isNativeScrolling) {
			main.setAttribute('data-has-scrollbar', true);
			sb = Scrollbar.init(main, { damping: 1 });
		}
	
		// let animator = null;
		// if (!isNativeScrolling) {
		// 	let isMobile = checkMobile(991);
		// 	animator = new Animator();
			
		// 	$(window).on('resize', () => {
		// 		if (checkMobile(991) && !isMobile) {
		// 			isMobile = true;
		// 		} else if (!checkMobile(991) && isMobile) {
		// 			isMobile = false;
		// 		}
	
		// 		// splitTitles();
	
		// 		animator.setAnimations(Animations.get(isMobile));
		// 		animator.updateElements();
		// 	}).trigger('resize');
	
		// 	imagesLoaded(document.body, { background: true }, () => {
		// 		animator.updateElements();
		// 	});
		// }
	}

	const experience = document.querySelector('[data-experience]');
	if (experience) {
		const exp = new Experience();
		exp.init();
	}
	
	// 2. This code loads the IFrame Player API code asynchronously.
	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	VideoPlayer.init();
	ExperiencePlayer.init();
	
	const bigSlider = $('[data-big-slider]');
	const thumbSlider = $('[data-thumb-slider]');
	
	if (bigSlider.length > 0 && thumbSlider.length > 0) {
		thumbSlider.imagesLoaded().done(() => {
			bigSlider.slick({
				rows: 0,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: true,
				asNavFor: '[data-thumb-slider]',
			});
		
			thumbSlider.slick({
				rows: 0,
				slidesToShow: 3,
				slidesToScroll: 1,
				asNavFor: '[data-big-slider]',
				arrows: true,
				focusOnSelect: true,
			});
		});
	}

	ShopModal.init();
	ProductFeatures.init();
	StickySidebar.init();
	Menu.init();
	InPageNav.init();
	HeaderVideo.init();
	Reveal.init();
	ShareSnippet.init();
});

export const getScrollBar = () => sb;
