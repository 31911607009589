'format es6';
'use strict';

import $ from 'jquery';
const SRCSET_REGEX = /^(\(.+\) )?(.+)/gm;

export class ResponsiveVideos {
	init() {
		this.videos = Array.from(document.querySelectorAll('[data-responsive-video]'));

		if (this.videos.length === 0) return;

		this.checkVideosSrc();

		$(window).on('resize', this.checkVideosSrc);
	}

	checkVideosSrc = () => {
		this.videos.forEach((vid) => {
			this.parseSrcset(vid, vid.dataset.videoSrcset, 'src');

			if (vid.dataset.posterSrcset) {
				this.parseSrcset(vid, vid.dataset.posterSrcset, 'poster');
			}
		});
	}

	parseSrcset = (vid, props, attr) => {
		const srcset = props;
		const sources = srcset.split(',').map(s => s.trim()).map((src) => {
			const arr = SRCSET_REGEX.exec(src);
			const obj = {
				media: null,
				url: null,
			};

			if (arr === null) {
				obj.url = src;
			} else {
				obj.media = arr[1];
				obj.url = arr[2];
			}

			return obj;
		}).some((obj) => {
			if ((window.matchMedia(obj.media).matches || !obj.media) && vid[attr] !== obj.url) {
				vid[attr] = obj.url;
				return true;
			}
			return false;
		});
	}
}
