'format es6';
'use strict';

import 'babel-polyfill';
import $ from 'jquery';
import { scrollToElem } from './utils/scrollTo';
import { isElementVisible, isVisibleScrollBar } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';
import Scrollbar from 'smooth-scrollbar';

let outsideNav;
let insideNav;
let anchorsMenu;
let anchors;
let anchorTargets;
let anchorsArray;

let scrollbar;
let lastScrollValue;

function anchorVisible(i, el) {
	const elemTop = el.getBoundingClientRect().top;
	const elemHeight = el.getBoundingClientRect().height;

	if (elemTop <= (elemHeight * (3 / 4))) {
		const anchorId = el.getAttribute('id');
		anchorsArray.forEach((a) => {
			const func = a.getAttribute('href') === `#${anchorId}` ? 'add' : 'remove';
			a.classList[func]('current');
		});
	} 
}

function onScroll() {
	const scrollTop = isNativeScrolling ? $(window).scrollTop() : scrollbar.scrollTop;

	if (lastScrollValue === undefined) {
		lastScrollValue = scrollTop;
	}

	lastScrollValue = scrollTop;

	anchorTargets.each(anchorVisible);

	const isNavAtTop = isNativeScrolling ? insideNav.offset().top - $(window).scrollTop() <= 0 : insideNav.offset().top <= 0;

	if (isNavAtTop) {
		outsideNav.show();
	} else {
		outsideNav.hide();
	}
}

function onAnchorClick(e) {
	e.preventDefault();
	const clicked = $(e.currentTarget);
	const id = clicked.attr('href').substring(1);
	const target = anchorTargets.filter(`[id="${id}"]`);

	anchors.removeClass('current');
	clicked.addClass('current');

	if (target.length > 0) {
		if (isNativeScrolling) {
			scrollToElem(target, { 
				container: $(window),
				offset: -80,
			});
		} else {
			scrollbar.scrollIntoView(target[0], {
				alignToTop: true,
				offsetTop: 80,
			});
		}
	}
}

function createAnchors() {
	const ctn = anchorsMenu.find('[data-anchors-wrapper]');

	anchorTargets.each((i, el) => {
		const target = $(el);
		ctn.append($(`<a href="#${target.attr('id')}" class="nav-item" data-anchor><span>${target.data('anchor-label')}</span></a>`));
	});

	anchors = $('[data-anchor]', anchorsMenu);
	anchorsArray = anchors.toArray();

	const btnWidth = 200;
	const ctnSmWidth = (anchors.length * btnWidth < 992) ? '992px' : `${anchors.length * btnWidth}px`;
	const ctnXsWidth = (anchors.length * btnWidth < 480) ? '480px' : `${anchors.length * btnWidth}px`;

	ctn.append(`
		<style>
			@media screen and (max-width: 992px) {
				.inpage-nav-spacer .inpage-nav {
					width: calc(${ctnSmWidth});
				}
				.inpage-nav-spacer .inpage-nav > a {
					width: calc(100 / ${anchors.length}%);
				}
			}

			@media screen and (max-width: 480px) {
				.inpage-nav-spacer .inpage-nav {
					width: calc(${ctnXsWidth});
				}
			}
		</style>
	`);

	anchors.on('click.crux.anchors', onAnchorClick);
}

export default {
	init() {
		const navContent = $(`
			<div class="sticky-nav" data-outside-nav>
				<div class="container">
					<div class="row">
						<div class="inpage-nav-wrapper" data-anchors-container>
							<div class="col-md-12 inpage-nav-container" data-inpage-scrollbar>
								<div class="inpage-nav" data-anchors-wrapper>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		`);

		anchorTargets = $('[data-anchor]');
		
		/* if empty return */
		if (anchorTargets.length === 0) return;
		
		navContent.clone().insertAfter($('.header').eq(0));
		navContent.clone().insertBefore($('main'));

		outsideNav = $('body > [data-outside-nav]');
		insideNav = $('main [data-anchors-container]');
		
		/* Select anchors */
		anchorsMenu = $('[data-anchors-container]');
		
		/* init scrollbar */
		scrollbar = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		
		createAnchors();

		if (isNativeScrolling) {
			document.addEventListener('scroll', (e) => {
				onScroll(e);
			});
			onScroll(null);
		} else {
			if (!scrollbar) return false;
			
			const inpageNav = document.querySelector('main [data-anchors-container]');
			if (inpageNav) {
				Scrollbar.init(inpageNav);
			}

			scrollbar.addListener(onScroll);
			onScroll();
		}
	},
};
