'format es6';
'use strict';

import clamp from './clamp';

function distance2(p1, p2) {
	const dx = p1.x - p2.x;
	const dy = p1.y - p2.y;
	return (dx * dx) + (dy * dy);
}

const PRECISION = 5;

const cachedPaths = {};

export function getPointAtLength(path, length) {
	const pathID = path.getAttribute('d');
	const cached = cachedPaths[pathID] = cachedPaths[pathID] || {};
	return cachedPaths[pathID][length] = cachedPaths[pathID][length] || path.getPointAtLength(length);
}

export function getLengthAtPoint(path, point) {
	let length = 0;
	const pathLength = path.getTotalLength();

	let found = false;
	for (length; length < pathLength && !found; length += PRECISION) {
		const p2 = getPointAtLength(path, length);
		if (distance2(point, p2) < PRECISION) {
			found = true;
		}
	}

	return clamp(length, 0, pathLength);
}

