'format es6';
'use strict';

import $ from 'jquery';
import { isMobile } from './utils/isMobile';
import { isNativeScrolling } from './AppSettings';
import { getScrollBar } from './Main';

const $window = $(window);

let stickySidebar;
let stickySidebarContainer;
let naturalOffset;
let containerNaturalOffset;

const TOP_OFFSET = 30;
const MOBILE_TRESHOLD = 992;

function scrollHandler() {
	const st = isNativeScrolling ? $window.scrollTop() : getScrollBar().scrollTop;

	if (naturalOffset) {
		if (st >= naturalOffset.top - TOP_OFFSET) {
			if (st < naturalOffset.top + stickySidebar.parent().height() - stickySidebar.width()) {
				stickySidebar.css({
					position: 'absolute',
					left: stickySidebar.parent().css('padding-left'),
					top: st - naturalOffset.top + TOP_OFFSET,
					bottom: 'auto',
				});
			}
		} else {
			stickySidebar.css({
				position: 'relative',
				top: 'auto',
				left: 'auto',
				bottom: 'auto',
			});
		}
	}
}

function resizeHandler() {
	if (isMobile(767)) {
		stickySidebar.parent().height('auto');
		stickySidebar.css({
			position: 'relative',
			top: 'auto',
			left: 'auto',
			bottom: 'auto',
		});

		removeScrollEvents();
	} else {
		stickySidebar.parent().height(stickySidebarContainer.height());
		
		naturalOffset = stickySidebar.css({
			position: 'relative',
			top: 'auto',
			left: 'auto',
			bottom: 'auto',
		}).offset();
		
		containerNaturalOffset = stickySidebarContainer.offset();
		
		addScrollEvents();
		scrollHandler();
	}
}

function addScrollEvents() {
	if (isNativeScrolling) {
		document.addEventListener('scroll', scrollHandler);
	} else {
		getScrollBar().addListener(scrollHandler);
	}

	scrollHandler();
}

function removeScrollEvents() {
	if (isNativeScrolling) {
		document.removeEventListener('scroll', scrollHandler);
	} else {
		getScrollBar().addListener(scrollHandler);
		scrollHandler();
	}
}

function addResizeEvents() {
	$window.off('resize.brim.sticky_sidebar').on('resize.brim.sticky_sidebar', resizeHandler);
}

export default {
	init() {
		stickySidebar = $('[data-fixed-menu]');
		stickySidebarContainer = $('[data-fixed-menu-container]');

		if (stickySidebar.length === 0) return;

		addResizeEvents();
		$window.trigger('resize');
	},
};
