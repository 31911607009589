const prefix = window.location.hostname === 'brim.wp' ? '/wp-content/themes/brim/img/experience' : 'https://s3.amazonaws.com/brim-experience';
const manifest = window.manifest = {
    intro: {
        src: prefix + '/intro.mp4',
        choiceLoop: {
            src: prefix + '/intro-loop.mp4',
            loop: true,
            soundLoop: prefix + '/interactive-video-soundtrack.mp3',
        },
    },
    loadingQuotes: [
        'Teddy Roosevelt drank a gallon of coffee a day.',
        'Voltaire is said to have drunk 40-50 cups of coffee a day.',
        'Ludwig Van Beethoven took his coffee with exactly 60 beans per cup.',
        'David Lynch has anywhere from four to seven cups of coffee a day -- with plenty of sugar.',
        'Johann Sebastian Bach wrote a short opera about coffee obsession.',
        '“Without my morning coffee, I’m just like a dried-up piece of roast goat”<br/>The coffee Cantata',
    ],
    demos: [
        {
            name: 'Pour<br/>Over<br/>Coffee<br/>Kit',
            slug: 'pour-over',
            loadingLoop: prefix + '/loop-loading.mp4',
            // choiceRadial: prefix + '/gradient-pour-over.png',
            steps: [
                {
                    type: 'video',
                    slug: 'pour-over-featured-video',
                    src: prefix + '/_pourover-1-featured.mp4',
                },
                {
                    type: 'interactive',
                    slug: 'pour-over-features',
                    slugProgress: 'pour-over-step-1',
                    name: 'FEATURES',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    svg: prefix + '/features.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    overlay: {
                        title: 'Slide<br/>to discover',
                        desc: '',
                        button: 'Try it',
                    },
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/po_seq_features/low/po-features-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/po_seq_features/high/po-features-{i}.jpg',
                        },
                        nIntroFrames: 0,
                        firstFrame: 1,
                        lastFrame: 91,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'pour-over-grinder-video',
                    slugProgress: 'pour-over-step-1',
                    src: prefix + '/_pourover-2-grinder.mp4',
                },
                {
                    type: 'interactive',
                    name: 'GRINDING',
                    slug: 'grinder',
                    slugProgress: 'pour-over-step-2',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Grind to<br/>perfection',
                        desc: 'Set it to medium',
                        button: 'Try it',
                    },
                    svg: prefix + '/grinder.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/po_seq_grinder/low/po-grinder-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/po_seq_grinder/high/po-grinder-{i}.jpg',
                        },
                        nIntroFrames: 30,
                        firstFrame: 1,
                        lastFrame: 77,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'pour-over-shake-video',
                    slugProgress: 'pour-over-step-2',
                    src: prefix + '/_pourover-3-shake.mp4',
                },
                {
                    type: 'interactive',
                    name: 'SHAKING',
                    slug: 'shake',
                    slugProgress: 'pour-over-step-3',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Shake to<br/>even out<br/>the bed',
                        desc: '',
                        button: 'Try it',
                    },
                    svg: prefix + '/shake.svg',
                    percentAutoComplete: 0.95,
                    lineAnimDuration: 0.6,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/po_seq_shake/low/po-shake-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/po_seq_shake/high/po-shake-{i}.jpg',
                        },
                        nIntroFrames: 40,
                        firstFrame: 1,
                        lastFrame: 87,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'pour-over-spiral-video',
                    slugProgress: 'pour-over-step-3',
                    src: prefix + '/_pourover-4-spiral.mp4',
                },
                {
                    type: 'interactive',
                    name: 'BLOOMING',
                    slug: 'spiral',
                    slugProgress: 'pour-over-step-4',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Pour over to bloom',
                        desc: 'Spiral your way towards<br/>the centre of the filter',
                        button: 'Try it',
                    },
                    svg: prefix + '/spiral.svg',
                    percentAutoComplete: 0.95,
                    lineAnimDuration: 1.2,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/po_seq_spiral/low/po-spiral-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/po_seq_spiral/high/po-spiral-{i}.jpg',
                        },
                        nIntroFrames: 30,
                        lastFrame: 87,
                        firstFrame: 1,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slugProgress: 'pour-over-step-4',
                    src: prefix + '/_pourover-5-pour.mp4',
                },
            ]
        },
        {
            name: 'Smart Valve<br/>Cold Brew<br/>Coffee<br/>Maker',
            slug: 'cold-brew',
            loadingLoop: prefix + '/loop-loading.mp4',
            // choiceRadial: prefix + '/gradient-cold-brew.png',
            steps: [
                {
                    type: 'video',
                    slug: 'cold-brew-features-video',
                    src: prefix + '/_coldbrew-1-featured.mp4',
                },
                {
                    type: 'interactive',
                    name: 'FEATURES',
                    slug: 'features',
                    slugProgress: 'cold-brew-step-1',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    svg: prefix + '/features.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    overlay: {
                        title: 'Slide to<br/>discover',
                        desc: '',
                        button: 'Try it',
                    },
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/cb_seq_featured/low/cb-featured-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/cb_seq_featured/high/cb-featured-{i}.jpg',
                        },
                        nIntroFrames: 0,
                        firstFrame: 1,
                        lastFrame: 52,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'cold-brew-grinder-video',
                    slugProgress: 'cold-brew-step-1',
                    src: prefix + '/_coldbrew-2-grinder.mp4',
                },
                {
                    type: 'interactive',
                    name: 'GRINDING',
                    slug: 'grinder',
                    slugProgress: 'cold-brew-step-2',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'GRIND TO<br/>PERFECTION',
                        desc: 'Set it to coarse',
                        button: 'Try it',
                    },
                    svg: prefix + '/grinder.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/cb_seq_grinder/low/cb-grinder-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/cb_seq_grinder/high/cb-grinder-{i}.jpg',
                        },
                        nIntroFrames: 30,
                        firstFrame: 1,
                        lastFrame: 80,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'cold-brew-clock-video',
                    slugProgress: 'cold-brew-step-2',
                    src: prefix + '/_coldbrew-3-clock.mp4',
                },
                {
                    type: 'interactive',
                    name: 'CHILLING',
                    slug: 'clock',
                    slugProgress: 'cold-brew-step-3',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'WITH TIME,<br/>COMES QUALITY',
                        desc: '',
                        button: 'Try it',
                    },
                    svg: prefix + '/clock.svg',
                    percentAutoComplete: 0.95,
                    lineAnimDuration: 0.8,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/cb_seq_clock/low/cb-clock-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/cb_seq_clock/high/cb-clock-{i}.jpg',
                        },
                        nIntroFrames: 0,
                        firstFrame: 1,
                        lastFrame: 88,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: 'cold-brew-putdown-video',
                    slugProgress: 'cold-brew-step-3',
                    src: prefix + '/_coldbrew-4-deposer.mp4',
                },
                {
                    type: 'interactive',
                    name: 'DRIPPING',
                    slug: 'putdown',
                    slugProgress: 'cold-brew-step-4',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'DRIPPING',
                        desc: 'Position the brewing<br/>container on top of<br/>the decanter',
                        button: 'Try it',
                    },
                    svg: prefix + '/putdown.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/cb_seq_putdown/low/cb-putdown-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/cb_seq_putdown/high/cb-putdown-{i}.jpg',
                        },
                        nIntroFrames: 20,
                        firstFrame: 1,
                        lastFrame: 87,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slugProgress: 'cold-brew-step-4',
                    src: prefix + '/_coldbrew-5-drip.mp4',
                },
            ],
        },
        {
            name: 'Smart Valve<br/>Cold Brew<br/>Coffee<br/>Maker',
            slug: '8-cup-pour-over-coffee-maker',
            loadingLoop: prefix + '/loop-loading.mp4',

            steps: [
                {
                    type: 'video',
                    slug: '8-cup-pour-over-featured-video',
                    src: prefix + '/8cpo-1-featured.mp4',
                    //src: '/wp-content/themes/brim/img/experience' + '/8cpo-1-featured.mp4',
                },
                {
                    type: 'interactive',
                    slug: '8-cup-pour-over-features',
                    slugProgress: '8-cup-pour-over-step-1',
                    name: 'FEATURES',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    svg: prefix + '/8cpo-features.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    overlay: {
                        title: 'Slide<br/>to discover',
                        desc: '',
                        button: 'Try it',
                    },
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/8cpo_seq_features/low/8cpo-features-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_features/low/8cpo-features-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/8cpo_seq_features/high/8cpo-features-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_features/high/8cpo-features-{i}.jpg',
                        },
                        nIntroFrames: 0,
                        firstFrame: 1,
                        lastFrame: 61,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: '8-cup-pour-over-grinder-video',
                    slugProgress: '8-cup-pour-over-step-1',
                    src: prefix + '/8cpo-2-grinding.mp4',
                    //src: '/wp-content/themes/brim/img/experience' + '/8cpo-2-grinding.mp4',
                },
                {
                    type: 'interactive',
                    name: 'GRINDING',
                    slug: '8-cup-pour-over-grinder',
                    slugProgress: '8-cup-pour-over-step-2',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Grind to<br/>perfection',
                        desc: 'Set it to medium',
                        button: 'Try it',
                    },
                    svg: prefix + '/8cpo-grinding.svg',
                    //svg: '/wp-content/themes/brim/img/experience' + '/grinding.svg',
                    percentAutoComplete: 1,
                    lineAnimDuration: 0.4,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/8cpo_seq_grinding/low/8cpo-grinding-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_grinding/low/8cpo-grinding-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/8cpo_seq_grinding/high/8cpo-grinding-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_grinding/high/8cpo-grinding-{i}.jpg',
                        },
                        nIntroFrames: 30,
                        firstFrame: 1,
                        lastFrame: 59,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: '8-cup-pour-over-shake-video',
                    slugProgress: '8-cup-pour-over-step-2',
                    src: prefix + '/8cpo-3-scooping.mp4',
                    //src: '/wp-content/themes/brim/img/experience' + '/8cpo-3-scooping.mp4',
                },
                {
                    type: 'interactive',
                    name: 'SCOOPING',
                    slug: 'scooping',
                    slugProgress: '8-cup-pour-over-step-3',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Add<br>ground coffee',
                        desc: '',
                        button: 'Try it',
                    },
                    svg: prefix + '/8cpo-scooping.svg',
                    //svg: '/wp-content/themes/brim/img/experience' + '/scooping.svg',
                    percentAutoComplete: 0.95,
                    lineAnimDuration: 0.6,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/8cpo_seq_scooping/low/8cpo-scooping-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_scooping/low/8cpo-scooping-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/8cpo_seq_scooping/high/8cpo-scooping-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_scooping/high/8cpo-scooping-{i}.jpg',
                        },
                        nIntroFrames: 1,
                        firstFrame: 1,
                        lastFrame: 32,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slug: '8-cup-pour-over-spiral-video',
                    slugProgress: '8-cup-pour-over-step-3',
                    src: prefix + '/8cpo-4-water.mp4',
                    //src: '/wp-content/themes/brim/img/experience' + '/8cpo-4-water.mp4',
                },
                {
                    type: 'interactive',
                    name: 'WATER',
                    slug: 'water',
                    slugProgress: '8-cup-pour-over-step-4',
                    soundLoop: prefix + '/interactive-video-soundtrack.mp3',
                    overlay: {
                        title: 'Fill with water',
                        desc: 'Drag the bottle towards<br>the water container',
                        button: 'Try it',
                    },
                    svg: prefix + '/8cpo-water.svg',
                    //svg: '/wp-content/themes/brim/img/experience' + '/water.svg',
                    percentAutoComplete: 0.95,
                    lineAnimDuration: 1.2,
                    options: {
                        autoInit: false,
                        low: {
                            width: 960,
                            height: 540,
                            basePath: prefix + '/8cpo_seq_water/low/8cpo-water-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_water/low/8cpo-water-{i}.jpg',
                        },
                        hi: {
                            width: 1920,
                            height: 1080,
                            basePath: prefix + '/8cpo_seq_water/high/8cpo-water-{i}.jpg',
                            //basePath: '/wp-content/themes/brim/img/experience' + '/8cpo_seq_water/high/8cpo-water-{i}.jpg',
                        },
                        nIntroFrames: 14,
                        lastFrame: 132,
                        firstFrame: 1,
                        delayBeforeHi: 100,
                    },
                },
                {
                    type: 'video',
                    slugProgress: '8-cup-pour-over-step-4',
                    src: prefix + '/8cpo-5-pour.mp4',
                    //src: '/wp-content/themes/brim/img/experience' + '/8cpo-5-pour.mp4',
                }
            ],
        },
    ],
};


export default manifest;