'format es6';
'use strict';

export const STEP_TYPE_INTERACTIVE = 'interactive';
export const STEP_TYPE_VIDEO = 'video';

import Promise from 'Promise';
import Namespace from '../Namespace';

import manifest from './manifest';
import { Demo } from './Demo';
import { StepVideo } from './StepVideo';
import { DemoButton } from './DemoButton';

export class Experience {
    constructor() {
        this.intro = null;
        this.currentDemo = null;
        this.introLoop = null;
        this.splash = document.querySelector('[data-splash]');
        this.choices = document.querySelector('[data-choices]');
        this.ctn = document.querySelector('[data-demo-ctn]');
        this.btnStart = document.querySelector('[data-start-experience]');
        this.node = document.querySelector('[data-experience]');
    }

    init = () => {
        this.btnStart.addEventListener('click', () => {
            this.splash.style.display = 'none';

            let defaultDemo = Namespace.showDemo;

            console.log('===DEFAULT DEMO===')
            console.log(defaultDemo)

            if (defaultDemo) {
                const demo = manifest.demos.reduce((c, d) => {
                    return d.slug === defaultDemo ? d : c;
                }, null);
                if (demo) {
                    this.choices.style.display = 'none';
                    this.initDemo(demo);
                } else {
                    defaultDemo = false;
                }
            }

            if (!defaultDemo) {
                this.intro = new StepVideo(manifest.intro, true);
                this.introLoop = new StepVideo(manifest.intro.choiceLoop);

                this.intro.appendTo(this.ctn);
                this.intro.ready.then(() => {
                    this.intro.play();
                });
                this.intro.play();

                this.intro.ended.then(() => {
                    this.showChoices();
                });
            }
        });
    };

    showChoices = () => {
        this.intro.remove();
        this.introLoop.appendTo(this.ctn);
        this.introLoop.play();

        this.choices.classList.add('active');

        const buttons = manifest.demos.map((demo) => {
            const btn = new DemoButton(demo);
            this.choices.appendChild(btn.node);
            return btn.clicked;
        });

        Promise.race(buttons).then((choice) => {
            this.introLoop.remove();
            this.choices.innerHTML = "";

            this.initDemo(choice);
            this.choices.style.display = 'none';
        }).catch(e => console.error(e));
    }

    initDemo(demo) {
        this.currentDemo = new Demo(demo, this.ctn, this.node);
    }
}
