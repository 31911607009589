import $ from 'jquery';
import ns from 'ns';
import gsap, { TweenMax } from 'gsap';
import Imagesloaded from 'imagesloaded';
import Promise from 'Promise';

import clamp from '../utils/clamp';

const HI = 'hi';
const LOW = 'low';

const noop = () => {};

const default_settings = {
	autoInit: false,
	low: {
		width: 1024,
		height: 576,
		basePath: '/low_{i}.jpg',
	},
	hi: {
		width: 1920,
		height: 1080,
		basePath: '/high_{i}.jpg',
	},
	onReady: noop,
	lastFrame: 300,
	firstFrame: 1,
	nIntroFrames: 0,
	delayBeforeHi: 100,
};

export default function ImageSequence(canvas, options) {
	// const settings = {
	// 	...default_settings,
	// 	...options,
	// };
	const frames = [];
	const settings = $.extend(true, {}, default_settings, options);
	settings.ratio = settings.low.width / settings.low.height;
	settings.hiLowRatio = settings.hi.width / settings.low.width;
	settings.numFrames = (1 + settings.lastFrame) - settings.firstFrame;
	const totalFrames = settings.numFrames;

	const ctx = canvas.getContext('2d');

	let currentFrame = settings.firstFrame;
	let ready = false;
	let showHighresTimer;

	const getSingleImageLoader = (f, qual) => {
		frames[qual] = frames[qual] || [];
		const thisFrames = frames[qual];
		const img = document.createElement('img');
		img.src = settings[qual].basePath.replace('{i}', f);
		thisFrames[f] = img;
		return img;
	};

	const loadImages = (qual, callback, id) => {
		const cnt = $('<div style="display:none">');
		if (undefined !== id) {
			cnt.append(getSingleImageLoader(id, qual));
		} else {
			for (let i = settings.firstFrame; i <= settings.lastFrame; i++) {
				cnt.append(getSingleImageLoader(i, qual));
			}
		}

		$('body').append(cnt);

		return new Imagesloaded(cnt, () => {
			// console.log('all loaded');
			callback();
		});
	};
	
	const canvasProps = (() => {
		const props = {};
		const update = () => {
			props.availableW = canvas.clientWidth * window.devicePixelRatio;
			props.availableH = canvas.clientHeight * window.devicePixelRatio;
			props.ratio = props.availableW / props.availableH;

			props.destW = props.availableW;
			props.destH = props.availableH;
			props.destX = 0;
			props.destY = 0;

			//large
			if (props.ratio >= settings.ratio) {
				// console.log('large');
				props.destH = Math.round(props.availableW / settings.ratio);
				props.destY = (props.availableH - props.destH) / 2;
			//haut
			} else {
				// console.log('haut');
				props.destW = Math.round(props.availableH * settings.ratio);
				props.destX = (props.availableW - props.destW) / 2;
			}

			canvas.width = props.availableW;
			canvas.height = props.availableH;
		};

		$(document).ready(update);
		$(window).on('resize.canvasprops', update);
		this.updateProps = update;

		return () => props;
	})();

	const updateFrame = (f, qual) => {
		qual = qual || LOW;

		if (frames[qual][f]) {
			ctx.drawImage(frames[qual][f], 0, 0, settings[qual].width, settings[qual].height, canvasProps().destX, canvasProps().destY, canvasProps().destW, canvasProps().destH);
		}
		if (qual === LOW) setHighresTimer(f);
	};

	const loadHiRes = (f) => () => {
		frames[HI] = frames[HI] || [];
		if (frames[HI][f]) {
			updateFrame(f, HI);
		} else {
			loadImages(HI, () => {
				updateFrame(f, HI);
			}, f);
		}
		// console.log('load hi '+f);
	};

	const setHighresTimer = (f) => {
		showHighresTimer = setTimeout(
			loadHiRes(f),
			settings.delayBeforeHi,
		);
	};

	this.updateCanvas = () => {
		this.updateProps();
		this.updateCurrentFrame();
	};

	this.updateCurrentFrame = () => {
		clearTimeout(showHighresTimer);
		updateFrame(currentFrame, HI);
	};

	this.gotoFrame = (obj) => {
		// console.log(arguments);
		const f = Math.round(obj.frame);
		currentFrame = f;
		
		clearTimeout(showHighresTimer);
		if (ready) {
			updateFrame(currentFrame);
		}
	};

	this.gotoInstant = (prc) => {
		const clampedPrc = clamp(prc, 0, 1);
		let targetFrame = Math.round(clampedPrc * (totalFrames - 1));
		if (targetFrame === 0) {
			targetFrame = settings.firstFrame;
		}
		this.gotoFrame({ frame: targetFrame });
	};

	this.gotoSmoothFrame = (frame, easeParam, onComplete) => {
		this.gotoSmooth(frame / settings.numFrames, easeParam, onComplete);
	};

	this.gotoSmooth = (() => {
		let tween;
		return (prc, easeParam, onComplete) => {
			easeParam = easeParam || gsap.Sine.easeOut;
			onComplete = onComplete || noop;
			const clampedPrc = clamp(prc, 0, 1);
			let targetFrame = Math.round(clampedPrc * (totalFrames - 1));
			if (targetFrame === 0) {
				targetFrame = settings.firstFrame;
			}

			const anim = {
				frame: currentFrame,
			};
			tween && tween.kill();
			tween = TweenMax.to(
				anim,
				Math.abs(currentFrame - targetFrame),
				{
					frame: targetFrame,
					ease: easeParam,
					useFrames: true,
					onUpdate: this.gotoFrame,
					onUpdateParams: [anim],
					onComplete,
				},
			);

		};
	})();

	this.playIntro = (cb) => {
		this.gotoSmoothFrame(settings.nIntroFrames, gsap.Linear.easeNone, cb);
	};

	this.init = () => {
		loadHiRes(settings.firstFrame)();

		$(window).on('resize.image-sequence', () => {
			updateFrame(currentFrame, HI);
		});

		return new Promise((resolve) => {
			loadImages(LOW, () => {
				ready = true;
				resolve();
			});
		});
	};
}
