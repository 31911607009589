'format es6';
'use strict';

import Promise from 'promise';

export class DemoButton {
	constructor(demo) {
		this.node = document.createElement('div');
		this.node.classList.add('choice-ctn');
		this.node.innerHTML = `
			<div class="choice">
				<div class="choice-mask"><div class="title">${demo.name}</div></div>
				<div class="choice-try-ctn"><div class="choice-mask"><div class="choice-try">Try it</div></div></div>
				<div class="choice-button"><div class="choice-button-inner"></div></div>
			</div>
		`;
		this.overlay = document.createElement('div');
		this.overlay.classList.add('choice-overlay');
		if (demo.choiceRadial) {
			this.overlay.style.backgroundImage = `url(${demo.choiceRadial})`;
		}
		this.node.appendChild(this.overlay);
		
		this.clicked = new Promise((resolve) => {
			this.node.addEventListener('click', () => {
				resolve(demo);
			});
		});
	}
}
