'format es6';
'use strict';

import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';

import { isElementVisible } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';

let scrollbar;

function isVisibleScrollBar(elem) {
	const { bounding } = scrollbar;

	const targetBounding = elem.getBoundingClientRect();

	// check overlapping
	const top = Math.max(bounding.top, targetBounding.top);
	const left = Math.max(bounding.left, targetBounding.left);
	const right = Math.min(bounding.right, targetBounding.right);
	const bottom = Math.min(bounding.bottom, targetBounding.bottom);

	return top < bottom && left < right;
}

function videoVisible(i, el) {
	const isVisible = isNativeScrolling ? isElementVisible(el.parentElement) : isVisibleScrollBar(el);
	if (isVisible) {
		el.play();
	} else {
		el.pause();
	}
}

function onScroll() {
	const videos = $('[data-video]');
	videos.each(videoVisible);
}

export default {
	init() {
		scrollbar = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		
		if (isNativeScrolling) {
			document.addEventListener('scroll', (e) => {
				onScroll();
			});
			onScroll(null);
		} else {
			if (!scrollbar) return false;
			
			scrollbar.addListener(onScroll);
			onScroll();
		}
	},
};
