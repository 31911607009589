'format es6';
'use strict';

import Promise from 'Promise';
import gsap, { TweenMax } from 'gsap';
import EventDispatcher from '../utils/EventDispatcher';
import { STEP_TYPE_VIDEO } from './Experience';

const devOnly = window.location.href.indexOf('.wp') >= 0;

export class StepVideo extends EventDispatcher {
	constructor(stepInfo, showSkip = devOnly) {
		super();

		this.type = STEP_TYPE_VIDEO;
		this.slug = stepInfo.slug;
		this.slugProgress = stepInfo.slugProgress;
		this.loop = stepInfo.loop;
		
		this.name = stepInfo.name;
		this.src = stepInfo.src;
		this.node = document.createElement('div');
		this.node.classList.add('experience-video');
		this.video = document.createElement('video');
		this.node.appendChild(this.video);

		this.video.setAttribute('preload', 'preload');
		this.video.setAttribute('playsinline', 'playsinline');
		this.video.setAttribute('webkit-playsinline', 'webkit-playsinline');
		
		if (this.loop) {
			this.video.setAttribute('muted', 'muted');
			this.video.setAttribute('loop', 'loop');

			this.hasSound = !!stepInfo.soundLoop;
			if (this.hasSound) {
				this.audio = document.createElement('audio');
				this.node.appendChild(this.audio);
				this.audio.src = stepInfo.soundLoop;
				this.audio.preload = true;
				this.audio.loop = true;
				this.audio.volume = 0.0;
			}
		}
		
		if (showSkip) {
			this.skipButton = document.createElement('div');
			this.skipButton.classList.add('video-skip-button');
			this.skipButton.innerHTML = 'Skip';
			this.node.appendChild(this.skipButton);
		}

		this.video.src = stepInfo.src;
	}

	videoEndedPromise = () => {
		this.endedResolve();
	}

	checkProgress = (cb) => {
		this.progressFunc = cb;
	}

	onProgress = (e) => {
		if (this.progressFunc) {
			this.progressFunc(this.video.currentTime / this.video.duration);
		}
	}

	setListeners = () => {
		this.ready = this.ready || new Promise(this.onReady);
		this.video.addEventListener('timeupdate', this.onProgress);
		this.ended = new Promise((resolve) => {
			this.endedResolve = resolve;
			this.video.addEventListener('ended', this.videoEndedPromise);
		});
		if (this.skipButton) {
			this.skipButton.addEventListener('click', this.onClickSkip);
		}
	}

	onReady = (resolve) => {
		const func = () => {
			this.video.removeEventListener('canplay', func);
			resolve();
		};
		this.video.addEventListener('canplay', func);
	};

	onClickSkip = () => {
		this.video.currentTime = this.video.duration - 5;
		this.skipButton.style.display = 'none';
	}
	
	start = () => {
		this.play();

		if (this.skipButton) {
			this.skipButton.style.display = '';
		}
	}
	
	appendTo = (elem) => {
		elem.appendChild(this.node);
		this.setListeners();
	}

	remove = () => {
		if (this.skipButton) {
			this.skipButton.removeEventListener('click', this.onClickSkip);
		}

		this.progressFunc = null;
		this.video.removeEventListener('timeupdate', this.onProgress);
		this.video.removeEventListener('ended', this.videoEndedPromise);

		this.video.pause();
		if (this.node.parentNode){
			this.node.parentNode.removeChild(this.node);
		}
	}

	play = () => {
		if (this.video.readyState > 0) {
			this.video.currentTime = 0;
		}
		const promise = this.video.play();

		if (this.hasSound) {
			TweenMax.to(this.audio, 0.6, { volume: 1 });
			this.audio.play();
		}
		
		if (promise !== undefined) {
			promise.catch(error => {
				if (!this.loop) {
					const buttons = document.querySelectorAll('.continue-button');
					Array.from(buttons).forEach(button => button.parentNode.removeChild(button));

					const buttonContinue = document.createElement('div');
					buttonContinue.innerHTML = 'press to continue';
					buttonContinue.classList.add('continue-button');
					this.node.appendChild(buttonContinue);

					const onClickContinue = () => {
						this.video.play();
						buttonContinue.style.display = 'none';
						console.log('onclick');
						this.node.removeChild(buttonContinue);
						buttonContinue.removeEventListener('click', onClickContinue);
					};
					buttonContinue.addEventListener('click', onClickContinue);
				}
			}).then(() => {
				// Auto-play started
			});
		}
	}
}
