'format es6';
'use strict';

import $ from 'jquery';
import { isMobile as checkMobile } from './utils/isMobile';

const CSS_ANIM_DURATION = 1200;
const OPENED_CLASS = 'opened';
const ACTIVE_CLASS = 'active';
const OVERFLOW_CLASS = 'overflow';
const MOBILE_BREAKPOINT = 1023;
let isMobile = checkMobile(MOBILE_BREAKPOINT);

let main;
let mainMenu;
let parentItems;
let childItems;
let menuBtn;
let sb;
let isOpen;

function onResize() {
	const wasMobile = isMobile;
	isMobile = checkMobile(MOBILE_BREAKPOINT);

	if (mainMenu && !wasMobile && isMobile || wasMobile && !isMobile) {
		mainMenu.classList.remove(OPENED_CLASS, OVERFLOW_CLASS);
		Array.from(parentItems).forEach(node => {
			node.classList.remove(ACTIVE_CLASS, OPENED_CLASS);
			document.querySelector('.submenu', node).style.display = isMobile ? 'none' : 'block';
		});
	}
}

//menu mobile click
function handleMenuButtonClick() {
	mainMenu.classList.toggle(OPENED_CLASS);
}

function closeMenu() {
	if (mainMenu.classList.contains(OPENED_CLASS)) {

		mainMenu.classList.remove(OPENED_CLASS);
		main.classList.remove(OPENED_CLASS);
		Array.from(parentItems).forEach(node => node.classList.remove(ACTIVE_CLASS));

		isOpen = false;
		main.removeEventListener('click', closeMenu);

		setTimeout(() => {
			mainMenu.classList.toggle(OVERFLOW_CLASS);
		}, mainMenu.classList.contains(OPENED_CLASS) ? 0 : CSS_ANIM_DURATION);
	}
}

function preventMenuClick(evt) {
	evt.stopPropagation();
}

//click sur parent qui a un submenu
function handleParentClick(e) {
    e.preventDefault();

    let alreadyActiveItems = Array.from(parentItems).filter(node => node !== e.currentTarget);

    e.currentTarget.classList.toggle(ACTIVE_CLASS);
    let keepMenuOpen = alreadyActiveItems.length > 0 && e.currentTarget.classList.contains(ACTIVE_CLASS);

    alreadyActiveItems.forEach(function (node) {
        return node.classList.remove(ACTIVE_CLASS);
    });

    if (isMobile) {
        e.currentTarget.classList.toggle(OPENED_CLASS);

        // Get the pitchforks!11!1
        $(e.currentTarget).find('ul').slideToggle();
    } else {
        isOpen = !isOpen;
        mainMenu.classList.toggle(OPENED_CLASS);
        main.classList.toggle(OPENED_CLASS);

        if (keepMenuOpen) {
            setTimeout(() => {
                mainMenu.classList.add(OPENED_CLASS);
                main.classList.add(OPENED_CLASS);
                mainMenu.classList.add(OVERFLOW_CLASS);
            }, CSS_ANIM_DURATION);
        } else {
            setTimeout(() => {
                mainMenu.classList.remove(OVERFLOW_CLASS);
            }, mainMenu.classList.contains(OPENED_CLASS) ? 0 : CSS_ANIM_DURATION);
        }

        if(isOpen) {
            mainMenu.addEventListener('click', preventMenuClick);
            main.addEventListener('click', closeMenu);
        } else {
            mainMenu.removeEventListener('click', preventMenuClick);
            main.removeEventListener('click', closeMenu);
        }
    }
}

export default {
	init() {
		window.addEventListener('resize', onResize);
		onResize();

		main = document.querySelector('[data-main-scrollbar]');
		mainMenu = document.querySelector('[data-main-menu]');
		parentItems = document.querySelectorAll('[data-parent-item]', mainMenu);
		childItems = document.querySelectorAll('[data-child-item]', parentItems);
		menuBtn = document.querySelector('[data-menu-btn]', mainMenu);

		if (!mainMenu) return;

		menuBtn.addEventListener('click', handleMenuButtonClick);

		// sb = document.querySelector('[data-main-scrollbar]');

		Array.from(parentItems).forEach((node) => {
			node.addEventListener('click', handleParentClick);
		});

		Array.from(childItems).forEach((node) => {
			node.addEventListener('click', (e) => {
				e.stopPropagation();
			});
		});
	},
};
