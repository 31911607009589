'format es6';
'use strict';

import $ from 'jquery';
import { scrollToElem } from './utils/scrollTo';
import { isElementVisible, isVisibleScrollBar } from './utils/isElementVisible';
import { isNativeScrolling } from './AppSettings';
import Scrollbar from 'smooth-scrollbar';

let scrollbar; 

function onScroll() {
	const $window = $(window);
	const animatedObjects = $('[data-animated], [data-animated-children] > *').toArray();
	
	const wh = $window.height();
	const st = $window.scrollTop();
	animatedObjects.forEach((obj) => {
		obj = $(obj);
		if (obj.offset().top <= (wh * (3 / 4)) + st) {
			obj.addClass('active').trigger('animation_on');
		}
	});
}

export default {
	init() {
		scrollbar = Scrollbar.get(document.querySelector('[data-main-scrollbar]'));
		
		if (isNativeScrolling) {
			document.addEventListener('scroll', (e) => {
				onScroll(e);
			});
			onScroll(null);
		} else {
			if (!scrollbar) return false;
			
			scrollbar.addListener(onScroll);
			onScroll();
		}
	
	},
};
